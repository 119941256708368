import React from "react"
import styled from "styled-components"

import { minD, betweenD, maxD } from "../../assets/styles/helpers/devices"

import { Button } from "../global/atoms/Button"

import { LineWrapperWithBottomSpace } from "../global/common/containers"
import { MainTitle, CenterTitle, CenterText } from "../global/common/text"
import { UnstyledList } from "../global/common/essentials"
import { dotsPseudoelement } from "../global/common/ornaments"

const PackagesList = styled(UnstyledList)`
  position: relative;
  margin: -1px 0 0 -1px;

  @media ${minD.tabletL} {
    display: flex;
    justify-content: center;
  }

  &::after {
    ${dotsPseudoelement}

    width: 800px;
    height: 730px;

    top: calc(var(--section-spacing) * -1);

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`

const Package = styled.li`
  --package-padding: 2.5rem;

  padding: var(--package-padding);
  margin: 0 -1px -1px 0;
  border: 1px solid var(--faded-gray);

  &:nth-child(odd) {
    --feature-list-border-color: var(--faded-gray);

    background-color: #fff;
  }

  &:nth-child(even) {
    --feature-list-border-color: var(--dark-line-green);

    background-color: var(--faded-green);
  }

  &:not(:last-child) {
    @media ${maxD.tabletL} {
      margin-bottom: 3rem;
    }
  }

  @media ${minD.tabletL} {
    width: 33.33%;
  }

  @media ${betweenD.mobileLTabletL} {
    text-align: center;
  }
`

const Price = styled.p`
  color: var(--main-green);
`

const FeaturesList = styled(UnstyledList)`
  width: calc(100% + var(--package-padding) * 2);
  margin: calc(var(--package-padding) * 0.8) calc(var(--package-padding) * -1)
    calc(var(--package-padding) * 0.9);
`

const Feature = styled.li`
  border-top: 1px solid var(--feature-list-border-color);
  padding: calc(var(--package-padding) * 0.5) var(--package-padding);

  &:last-of-type {
    border-bottom: 1px solid var(--feature-list-border-color);
  }
`

const FeatureLightText = styled.p`
  font-weight: 300;
`

const FeatureBoldText = styled.p`
  font-weight: 600;
`

const ButtonContainer = styled.footer`
  display: flex;
  justify-content: center;
`

const PackagesFooter = styled(CenterText)`
  padding-top: 2rem;
`

const Packages = ({
  data: { packagesTitle: title, packages: list, packagesFooter: footer },
}) => (
  <LineWrapperWithBottomSpace style={{ overflow: "hidden" }}>
    <CenterTitle dangerouslySetInnerHTML={{ __html: title }} />
    {list && (
      <PackagesList>
        {list.map(
          ({ price, header, features, button, itemFieldGroupName }, i) => {
            const { url, title, target } = button

            return (
              <Package key={`${itemFieldGroupName}-${i}`}>
                <MainTitle
                  dangerouslySetInnerHTML={{ __html: header }}
                  as="h3"
                  style={{ "--padding-bottom": "0.4rem" }}
                />
                <Price>{price}</Price>
                {features && (
                  <FeaturesList>
                    {features.map(
                      (
                        {
                          firstLine,
                          secondLine,
                          fieldGroupName: featureFieldGroupName,
                        },
                        i
                      ) => (
                        <Feature key={`${featureFieldGroupName}-${i}`}>
                          <FeatureLightText>{firstLine}</FeatureLightText>
                          <FeatureBoldText>{secondLine}</FeatureBoldText>
                        </Feature>
                      )
                    )}
                  </FeaturesList>
                )}
                {url && (
                  <ButtonContainer>
                    <Button to={url} target={target} theme="orange">
                      {title}
                    </Button>
                  </ButtonContainer>
                )}
              </Package>
            )
          }
        )}
      </PackagesList>
    )}
    <PackagesFooter dangerouslySetInnerHTML={{ __html: footer }} />
  </LineWrapperWithBottomSpace>
)

export default Packages
